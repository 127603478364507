<template>
  <div class="about">
    <my-swiper :bannerList="bannerList"></my-swiper>
    <div class="about-wrapper">
      <div class="yxj-container page-container swer" style="padding-bottom: 44px">
        <div class="title-wrapper" v-if="!$store.getters.mobile">
          <h1 class="title-label"><span>{{pageObj.title}}</span></h1>
        </div>
        <div class="qr-center mobile-qr sm box" style="margin-bottom: 30px" v-else>
          <div class="wx-tips">{{pageObj.title}}</div>
        </div>
        <!-- 移动端轮播 -->
        <div class="swiper-his" v-if="$store.getters.mobile">
          <!-- 移动端分液器 -->
          <div class="pagination" :style="fpaglineCenter ? 'justify-content: center' : null">
            <swiper-scoll class="cirl-wrapper" ref="fpagline" @mobileDomWidth="mobileDomWidth" scrollRef="pagline" :developHistory="developHistory" :realIndex="realIndex" :autoRealIndex="true">
              <div class="cril-box cril-left">
                <div class="cril"></div>
              </div>
              <div class="flex pagline">
                <div
                  class="cril-box" 
                  :class="'cril'+index"
                  v-for="(item, index) in developHistory"
                  :key="index"
                  @click="changePage(index)"
                >
                  <div class="cril" :style="realIndex === index ? 'opacity: 0' : ''"></div>
                  <div class="cril-alow" v-if="realIndex === index"></div>
                  <div class="name" :class="{act: realIndex === index}">{{item.title}}</div>
                </div>
              </div>
              <div class="cril-box cril-right">
                <div class="cril"></div>
              </div>
            </swiper-scoll>
          </div>
          <div class="swiper-mobile-wrapper">
            <swiper-scoll
              class="swiper-box"
              ref="fmobileAboutSwiper"
              scrollRef="mobileAboutSwiper"
              :realIndex="realIndex"
              :developHistory="developHistory"
              @moveEnd="moveEnd"
              @move="moveScroll = true"
            >
              <div class="custom"
                v-for="(item, index) in developHistory"
                :key="index"
              >
                <div class="left">
                  <h1 class="title" :class="{active: realIndex == index}" v-if="item.title">{{item.title}}</h1>
                  <h2 class="subtitle" v-if="item.name || item.address">
                    <span class="bold" v-if="item.name">{{item.name}}</span>
                    <span v-if="item.address">{{item.address}}</span>
                  </h2>
                  <h3 class="info">{{item.mobileInfo}}</h3>
                </div>
                <div class="right" @click.stop="showDialog(item.hurl, item.imgWidth)">
                  <img :width="item.mobildImgWidth" :height="item.mobildImgHeight" :src="item.url" />
                  <div class="show">查看</div>
                </div>
              </div>
            </swiper-scoll>
          </div>
        </div>
        <!-- PC端轮播 -->
        <div class="swiper-his" v-else>
          <swiper
            ref="develophis"
            :options="swiperOption"
            class="history"
            @slide-change-transition-start="onSwiperSlideChangeTransitionStart"
          >
            <swiper-slide
              v-for="(item, index) in developHistory"
              :key="index"
            >
              <div class="slide-his yxj-flex">
                <div class="left">
                  <h1
                    class="title"
                    v-if="item.title"
                  >{{item.title}}</h1>
                  <p
                    class="name"
                    v-if="item.name"
                  >{{item.name}}</p>
                  <p
                    class="address"
                    v-if="item.address"
                  >{{item.address}}</p>
                  <p
                    class="info layout0"
                    v-if="item.info && !$store.getters.mobile"
                    v-html="item.info"
                  ></p>
                  <p class="info layout0" v-if="$store.getters.mobile">{{item.mobileInfo}}</p>
                </div>
                <div class="right">
                  <img
                    @click="showDialog(item.hurl, item.imgWidth)"
                    :style="{marginLeft: item.pdLeft+'px'}"
                    :src="item.url"
                    :width="item.imgWidth"
                    :height="item.imgHeight"
                    alt=""
                  >
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <!-- 左右箭头 -->
          <div class="swiper-button-next swiper-button-nextBen"></div>
          <div class="swiper-button-prev swiper-button-prevBen"></div>
          <!-- PC分页器 -->
          <div class="pagination">
            <span
              class="head box item"
              :class="{none: realIndex === 0}"
              @click="changePage(realIndex - 1)"
            ></span>
            <span class="item" :class="{act: realIndex === index}" v-for="(item, index) in developHistory" :key="index" @click="changePage(index)"><i>{{item.title}}</i></span>
            <span
              class="foot box item"
              :class="{none: realIndex === developHistory.length - 1}"
              @click="changePage(realIndex + 1)"
            ></span>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="yxj-wechart-dialog"
      :modal-append-to-body="false"
      :lock-scroll="false"
      :visible.sync="wechart"
      @close="closeImg"
    >
      <img :width="weChartWidth" :src="weChartIcon">
    </el-dialog>
  </div>
</template>

<script>
import swiperScoll from '@/components/swiperScoll'
export default {
  name: 'About',
  data () {
    return {
      pageObj: {
        title : '发展历程',
        info: '移动端影响力领先的医学新媒体矩阵，为医生提供临床、政策、医学人文、职场等资讯服务。'
      },
      bannerList: [
        {
          to: '/about',
          img: require('@/assets/images/banner05.jpg'),
          mobileImg: require('@/assets/images/banner05m.png'),
          info: '2018年5月8日，医学界B轮融资发布会'
        },
        {
          to: '/about',
          img: require('@/assets/images/banner24.jpg'),
          mobileImg: require('@/assets/images/banner24m.png'),
          info: '2015年12月，医学界A轮融资发布会'
        },
        {
          to: '/about',
          img: require('@/assets/images/banner23.jpg'),
          mobileImg: require('@/assets/images/banner23m.png'),
          info: '2018年5月，医学界B轮融资发布会'
        },
        {
          to: '/about',
          img: require('@/assets/images/banner06.jpg'),
          mobileImg: require('@/assets/images/banner06m.png'),
          info: '2019年4月27日，医学界上海总部乔迁典礼'
        }
      ],
      swiperOption: {
        autoHeight: true,
        autoplay: {
          delay: 100000
        },
        grabCursor: true,
        navigation: {
          nextEl: '.swiper-button-nextBen',
          prevEl: '.swiper-button-prevBen'
        },
        speed: 500,
        loop: false
      },
      developHistory: [
        {
          title: '初心',
          name: '李勤子',
          address: '河南新安人（公元1953-2003年）',
          info: '创始人陈奇锐之母，死于龙胆泻肝丸引发的慢性肾衰竭。她<br/>的早逝，让陈奇锐萌生了创办“医学界”的初心：通过传播<br/>可靠的循证医学信息，“服务医生 改善医疗”。',
          url: require('@/assets/images/i_10.png'),
          hurl: require('@/assets/images/i_10_h.png'),
          imgWidth: 280,
          imgHeight: 357,
          mobildImgWidth: 57,
          mobildImgHeight: 76,
          pdLeft: 133
        },
        {
          title: '2010',
          info: '注册“医学界”商标和www.yxj.org.cn域名。',
          url: require('@/assets/images/i_11.png'),
          hurl: require('@/assets/images/i_11_h.png'),
          imgWidth: 280,
          imgHeight: 357,
          mobildImgWidth: 57,
          mobildImgHeight: 76,
          pdLeft: 156
        },
        {
          title: '2012',
          info: '医学界项目正式启动创业；<br/>4月1日上线官网www.yxj.org.cn，这一天成为公司司庆日；<br/>6月1日开通“@医学界网站”微博。',
          url: require('@/assets/images/i_12.png'),
          hurl: require('@/assets/images/i_12_h.png'),
          imgWidth: 280,
          imgHeight: 357,
          mobildImgWidth: 57,
          mobildImgHeight: 76,
          pdLeft: 148
        },
        {
          title: '2013',
          info: '微信号“医学界”开始运营，并注册“医学界内分泌频道”<br/>等一系列订阅号，组成医学专科矩阵。',
          url: require('@/assets/images/i_13.png'),
          hurl: require('@/assets/images/i_13_h.png'),
          imgWidth: 280,
          imgHeight: 357,
          mobildImgWidth: 57,
          mobildImgHeight: 76,
          pdLeft: 148
        },
        {
          title: '2014',
          info: '微信号粉丝突破300万。7月25日微信阅读号开放后，成为<br/>当时阅读量最大的医学新媒体（“医学界”进入新榜全领域<br/>年度订阅号排名500强第23名）。',
          url: require('@/assets/images/i_14.jpg'),
          hurl: require('@/assets/images/i_14_h.jpg'),
          imgWidth: 280,
          imgHeight: 357,
          mobildImgWidth: 57,
          mobildImgHeight: 76,
          pdLeft: 148
        },
        {
          title: '2015',
          info: '启动CME业务；<br/>9月17日上线“医生站”APP，半年后成为当时日活最高的医生APP；<br/>12月获得道彤投资、爱康国宾A轮融资。',
          url: require('@/assets/images/i_15.png'),
          hurl: require('@/assets/images/i_15_h.png'),
          imgWidth: 356,
          imgHeight: 280,
          mobildImgWidth: 57,
          mobildImgHeight: 43,
          pdLeft: 88
        },
        {
          title: '2016',
          info: '因为对医改的持续关注，获得国务院医改领导小组办公室感谢信。',
          url: require('@/assets/images/i_16.png'),
          hurl: require('@/assets/images/i_16_h.png'),
          imgWidth: 280,
          imgHeight: 357,
          mobildImgWidth: 57,
          mobildImgHeight: 76,
          pdLeft: 172
        },
        {
          title: '2017',
          info: '微信用户超700万，APP用户超150万；<br/>获评“上海市高新技术企业”；<br/>当年实现盈亏平衡。',
          url: require('@/assets/images/i_17.png'),
          hurl: require('@/assets/images/i_17_h.png'),
          imgWidth: 329,
          imgHeight: 253,
          mobildImgWidth: 57,
          mobildImgHeight: 43,
          pdLeft: 97
        },
        {
          title: '2018',
          info: '获评“上海市杨浦区科技小巨人企业”，列入区重点支持企业；<br/>首次实现当年盈利；<br/>4月获得复星集团B轮投资。',
          url: require('@/assets/images/i_18.png'),
          hurl: require('@/assets/images/i_18_h.png'),
          imgWidth: 358,
          imgHeight: 280,
          mobildImgWidth: 57,
          mobildImgHeight: 43,
          pdLeft: 79
        },
        {
          title: '2019',
          info: '与国家卫健委卫生发展研究中心签订战略合作协议，成功举办首届<br/>基层卫生健康创新发展大会（“基医会”）；<br/>微信粉丝近1000万，APP注册用户达200万+。',
          url: require('@/assets/images/i_19.png'),
          hurl: require('@/assets/images/i_19_h.png'),
          imgWidth: 358,
          imgHeight: 280,
          mobildImgWidth: 57,
          mobildImgHeight: 43,
          pdLeft: 104
        },
        {
          title: '2020',
          // <br/>引入券商辅导，制订3年上市计划。
          info: '在疫情中营收快速上升。',
          url: require('@/assets/images/i_20.png'),
          hurl: require('@/assets/images/i_20_h.png'),
          imgWidth: 358,
          imgHeight: 280,
          mobildImgWidth: 57,
          mobildImgHeight: 43,
          pdLeft: 84
        }
      ],
      realIndex: 0,
      wechart: false,
      weChartIcon: '',
      weChartWidth: 0,
      fpaglineCenter: false,
      clickImg: false,
      moveScroll: false
    }
  },
  created() {
    this.developHistory.forEach(res => {
      res.mobileInfo = res.info.replace(/<br\s*\/?>/g, '')
    })
  },
  computed: {
    swiper () {
      console.log(this.$refs.develophis.swiper)
      return this.$refs.develophis.swiper
    },

  },
  methods: {
    moveEnd(ctx) {
      // this.$refs.fpagline.scrollCustomX(ctx, this.realIndex)
      this.realIndex = ctx;
      // 图片可点击
      setTimeout(() => {
        this.moveScroll = false
      }, 200)
      console.log(ctx)
    },
    onSwiperSlideChangeTransitionStart () {
      this.realIndex = this.swiper.realIndex
    },
    changePage (page) {
      if (page < 0) {
        page = 0
      } else if (page >= this.developHistory.length) {
        page = this.developHistory.length - 1
      }
      if(this.$store.getters.mobile) {
        this.$refs.fmobileAboutSwiper.scrollCustomX(page, this.realIndex)
      } else {
        this.swiper.slideTo(page, 1000, false)
      }
      this.realIndex = page
    },
    showDialog (img, width) {
      this.clickImg = true;
      // 点击图片拖动结束拖动不显示图片
      if(this.clickImg == this.moveScroll){
        return
      }
      this.wechart = true
      this.weChartIcon = img,
      this.weChartWidth = width + 150
    },
    closeImg(){
      this.clickImg = false;
    },
    mobileDomWidth(val) {
      console.log(val)
      if(val.windowWidth >= val.domWidth) {
        this.fpaglineCenter = true;
      } else {
        this.fpaglineCenter = false;
      }
    }
  },
  components: {
    swiperScoll
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
