<template>
  <div
    :ref="scrollRef"
    @touchstart="aboutSwiperStart"
    @touchmove="aboutSwiperMove"
    @touchend="aboutSwiperEnd"
    @mousedown="aboutSwiperDown"
    @mousemove="aboutMouseMove"
    @mouseup="aboutSwiperUp"
    :style="animateSwiperBox()"
  >
    <slot></slot>
  </div>
</template>

<script>
import getOsInfo from "@/utils/getOsInfo";
export default {
  props: {
    scrollRef: {
      type: String,
      default: 'swiper1'
    },
    developHistory: {
      type: Array,
      default: []
    },
    realIndex: {
      type: Number,
      default: 0
    },
    // 移动阀值
    threshold: {
      type: Number,
      default: 80
    },
    autoRealIndex: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      mobileDomWidth: null,
      mobileAboutSwiperWidth: null,
      mobileAboutSwiperBoxWidth: null,
      scrollAutoWidth: null,
      os: null,
      clientWidth: null,
      startX: 0, // x偏移量
      moveX: 0,
      lastTransformX: 0, // 上次偏移量
      swiperTransformX: 0, // box偏移量
      swiperScoll: false, // 是否偏移
      swipeX: true,
      swipeY: true,
      xx: 0,
      yy: 0,
      XX: 0,
      YY: 0,
      clickMove: false,
    }
  },
  created () {
    this.init();
  },
  methods: {
    init () {
      this.getOs();
      this.getDom();
      window.addEventListener('resize', () => {
        this.getOs();
        this.getDom();
      })
    },
    getDom() {
      this.$nextTick(res => {
        this.mobileDomWidth = this.$refs[this.scrollRef].clientWidth;
        this.mobileAboutSwiperBoxWidth = this.mobileDomWidth / this.developHistory.length;
        this.mobileAboutSwiperWidth = this.mobileDomWidth - this.mobileAboutSwiperBoxWidth;
        this.scrollAutoWidth = this.mobileDomWidth - window.innerWidth
        this.$emit('mobileDomWidth', {
          domWidth: this.mobileDomWidth,
          windowWidth: this.clientWidth
        })
      })
    },
    aboutSwiperStart (e) {
      // e.stopPropagation();
      const targetTouches = this.clickMove ? e : e.targetTouches[0];
      this.swiperScoll = true;
      this.startX = targetTouches.clientX;
      this.xx = targetTouches.screenX;
      this.yy = targetTouches.screenY;
      this.swipeX = true;
      this.swipeY = true;
      // console.log('start',this.startX)
      this.$emit('start')
    },
    aboutSwiperMove (e) {
      // e.preventDefault();
      // 鼠标偏移量
      const targetTouches = this.clickMove ? e : e.targetTouches[0];
      this.XX = targetTouches.screenX;
      this.YY = targetTouches.screenY;
      if (this.swipeX && Math.abs(this.XX - this.xx) - Math.abs(this.YY - this.yy) > 0) {
        //左右滑动
        e.stopPropagation();//组织冒泡
        e.preventDefault();//阻止浏览器默认事件
        this.swipeY = false;
      }
      else if (this.swipeY && Math.abs(this.XX - this.xx) - Math.abs(this.YY - this.yy) < 0) {  //上下滑动
        this.swipeX = false;
        //上下滑动，使用浏览器默认的上下滑动
      }
      this.moveX = targetTouches.clientX - this.startX + this.lastTransformX;
      this.swiperTransformX = this.moveX;
      // 右划大于80 自动回弹
      // if(this.moveX > 200) {
      //   this.swiperScoll = false;
      //   this.startX = 0;
      //   this.swiperTransformX = 0;
      // }
      // console.log('move', this.swiperTransformX)
      this.$emit('move')
    },
    aboutSwiperEnd (e) {
      // e.stopPropagation();
      // 右划大于80 自动回弹
      this.swiperScoll = false;
      this.startX = 0;
      // 左滑或者组件开启根据索引自动滑动和当前dom小于屏幕宽度 不做偏移
      if (this.moveX > 0 || (this.autoRealIndex && this.clientWidth >= this.mobileDomWidth)) {
        this.swiperTransformX = 0;
      } else {
        // 屏幕宽度大于dom宽度 最有一个永远保持在屏幕右侧
        const autoWidth = this.mobileDomWidth - this.clientWidth
        var last = false;
        // console.log(this.swiperTransformX, autoWidth)
        if(this.mobileDomWidth > this.clientWidth && Math.abs(this.swiperTransformX) > autoWidth){
          this.swiperTransformX = -autoWidth;
          last = true;
        }
        if (Math.abs(this.swiperTransformX) > this.mobileAboutSwiperWidth) {
          this.swiperTransformX = -this.mobileAboutSwiperWidth;
        }
        if (this.autoRealIndex && Math.abs(this.swiperTransformX) > this.scrollAutoWidth) {
          this.swiperTransformX = -this.scrollAutoWidth;
        }
        this.lastTransformX = this.swiperTransformX;
        // 设置年份索引
        var Index = Math.round(Math.abs(this.swiperTransformX / this.mobileAboutSwiperBoxWidth));
        // 保持在最右侧
        last && (Index = this.developHistory.length - 1)
        this.$emit('moveEnd', Index)
      }
    },
    aboutSwiperDown(e) {
      this.clickMove = true;
      this.aboutSwiperStart(e)
    },
    aboutMouseMove(e) {
      e.preventDefault();//阻止浏览器默认事件
      if(this.clickMove) {
        this.aboutSwiperMove(e)
      }
    },
    aboutSwiperUp(e) {
      this.clickMove = false;
      this.aboutSwiperEnd(e)
    },
    scrollCustomX (index, realIndex) {
      console.log('scrollCustomX')
      if (index === realIndex) {
        return
      }
      // 点击年份 屏幕宽度大于dom宽度 最有一个永远保持在屏幕右侧
      const autoWidth = this.mobileDomWidth - this.clientWidth
      if(this.mobileDomWidth > this.clientWidth && index * this.mobileAboutSwiperBoxWidth > autoWidth){
        this.swiperTransformX = -autoWidth;
      }else{
        this.swiperTransformX = -index * this.mobileAboutSwiperBoxWidth;
      }
      this.lastTransformX = this.swiperTransformX;
    },
    animateSwiperBox () {
      return {
        'transform': `translateX(${this.swiperTransformX}px)`,
        'transition': this.swiperScoll ? 'none' : 'all .3s'
      }
    },
    getOs() {
      this.os = new getOsInfo();
      this.clientWidth = this.os.version == 'Iphone' || this.os.version == 'Android' ? document.body.clientWidth : window.innerWidth
    }
  },
  watch: {
    realIndex (val) {
      if (this.autoRealIndex && this.clientWidth < this.mobileDomWidth) {
        let w = val * this.mobileAboutSwiperBoxWidth - this.threshold;
        if (-w > 0) {
          w = 0
        }
        if (Math.abs(w) < this.scrollAutoWidth) {
          this.swiperTransformX = -w;
        } else if (Math.abs(w) >= this.scrollAutoWidth) {
          this.swiperTransformX = -this.scrollAutoWidth;
        }
        console.log(w)
        this.lastTransformX = this.swiperTransformX;
      }
    },
    scrollAutoWidth (val) {
      if (val < 0) {
        val = 0
      }
    }
  }
}
</script>

<style>
</style>